import { Component, OnInit, Renderer } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  page = 4;
  page1 = 5;
  focus;
  focus1;
  focus2;
  date: { year: number; month: number };
  model: NgbDateStruct;
  data: any;
  constructor(private renderer: Renderer, private http: HttpClient) {}
  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  ngOnInit() {
    let input_group_focus = document.getElementsByClassName("form-control");
    let input_group = document.getElementsByClassName("input-group");
    for (let i = 0; i < input_group.length; i++) {
      input_group[i].children[0].addEventListener("focus", function () {
        input_group[i].classList.add("input-group-focus");
      });
      input_group[i].children[0].addEventListener("blur", function () {
        input_group[i].classList.remove("input-group-focus");
      });
    }
    this.data = { name: "", email: "", text: "" };
  }

  sendMail() {
    // console.log(this.data);
    // var SENDGRID_API_KEY =
    //   "Bearer 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";
    // var url =
    //   "C:\\Users\\angel\\Documents\\Projects\\aucta\\src\\assets\\php\\mailSender.php";

    // var headers = {
    //   Authorization: SENDGRID_API_KEY,
    //   "Content-Type": "application/json",
    //   "Postman-Token": "2f16986d-2867-4534-af35-e383aa6798d3",
    // };
    // var body = {
    //   personalizations: [{ to: [{ email: "sashe.angelovski@aucta.dev" }] }],
    //   from: { email: "test@example.com" },
    //   subject: "Sending with SendGrid is Fun",
    //   content: [
    //     {
    //       type: "text/plain",
    //       value: "and easy to do anywhere, even with cURL",
    //     },
    //   ],
    // };
    // this.http.post(url, {}).subscribe((data) => {
    //   console.log(data);
    // });

    var mailText =
      "mailto:contact@aucta.dev?subject=Contact Aucta&body=" + this.data.text; // add the links to body
    window.location.href = mailText;
  }
}
